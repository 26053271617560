<template>
  <v-container fluid>
    <CreateCategories ref="eventForm" mode="edit" />
  </v-container>
</template>

<script>
import { showCategories } from "@/services/Planning";
import Swal from "sweetalert2";
import CreateCategories from "./CreateCategories.vue";

export default {
  name: "editDisharmonyReferences",
  data() {
    return {
      newCase: {
        id: "",
        name: "'"
      },
    };
  },
  async mounted() {
    /**
     * [ obtiene la data dependiendo del id]
     * @param {[id]} Identificador [id]
     */
    const id = this.$route.params.id;
    this.eventId = id;
    try {
      this.showLoader();
      const eventShowResponse = await showCategories(id);
      const newCase = {
        id: eventShowResponse.data.id,
        name: eventShowResponse.data.name,
        mandate: eventShowResponse.data.mandate
      };
      this.$nextTick(() => {
        this.$refs.eventForm.setEventData(newCase);
      });
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  methods: {
    setEventData(eventData) {
      /**
       * [ asigna la data ]
       * @param {[this.newCase]} NuevoCaso [Nuevo caso]
       */
      this.newCase = eventData;
    },
  },
  components: { CreateCategories },
};
</script>

<style scoped>

</style>
