<template>
  <main>
    <v-container fluid>
      <template>
        <v-breadcrumbs>
          <template>
            <v-breadcrumbs-item>
              <v-icon color="primary">mdi-home</v-icon>
              <v-icon>mdi-chevron-double-right</v-icon> LISTA CATEGORÍAS
              <template v-if="mode == 'edit'">
                <v-icon>mdi-chevron-double-right</v-icon> Detalle categoría {{newCase.name}}
              </template>
              <template v-else>
                <v-icon>mdi-chevron-double-right</v-icon> Crear tipo categoría
              </template>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>
      <v-card class="overflow-hidden">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <span class="green--text">Nombre</span>
              <v-text-field
                :error-messages="validationErrors.name"
                v-model="newCase.name"
                solo
                name="input-7-4"
                placeholder=""
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <span class="green--text">Nivel de mandato</span>
              <v-autocomplete
                  v-model="newCase.mandate"
                  :items="allPlanningReference?.mandate"
                  item-text="name"
                  item-value="id"
                  solo
                  :error-messages="validationErrors.mandate"
                  dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="text-center mt-2 mb-2">
        <v-btn small class="mx-2" color="primary" rounded @click="save()">
          <v-icon>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </div>
    </v-container>
  </main>
</template>

<script>
import sweetalert from "sweetalert2";
import { mapState, mapActions } from "vuex";
import {required} from "vuelidate/lib/validators";
import { updateCategories, createCategories} from "@/services/Planning";


export default {
  props: {
    mode: {
      default: "create"
    }
  },
  name: "createReceptionType.vue",
  data() {
    return {
      newCase: {
        id: "",
        name: "",
        mandate: "",
      },
      allPlanningReference: {},
      eventId: "",
    };
  },
  validations() {
    return {
      newCase: {
        name: { required },
        mandate: {required}
      },
    };
  },
  methods: {

    ...mapActions(["getPlanningReferenceValue"]),
    setEventData(eventData){
      this.newCase = eventData;
    },
    /**

   * [save Funcion que guarda cada una de las  categorias previa validación del contenido de los campos requeridos
    se agregan los valores a enviar en eventRequestData y dependiendo el modo (edit, update) se ejecutara
    el metodo correspondiente (updateCategories, createCategories) por ultimo se envia el respectivo mensaje]

   * @param {[this.newCase.name]} NombreNuevoCaso [nombre]

   * @param {[this.newCase.mandate]} nombredeMandato [mandato]

   */
    async save() {
      this.$v.newCase.$touch();
      if (this.$v.newCase.$invalid) return;
      try {
        this.showLoader();

        const eventRequestData = {
          name: this.newCase.name,
          mandate: this.newCase.mandate,
        };

        this.mode === "edit"
            ? await updateCategories(eventRequestData, this.newCase.id)
            : await createCategories(eventRequestData);

        let varSuccess = this.mode === "edit"
            ? "actualizado"
            : "creado"

        await sweetalert.fire(
          `Registro ${varSuccess} correctamente`,
          "",
          "success"
        );
        await this.getPlanningReferenceValue();
        this.hideLoader();


        this.$router.push({
            name: "planning.valueReferences"
        });

      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
  async created() {
    /**
     * [ trae del store todos los planes de referencia ]
     * @param {[this.allPlanningReference]} PlanesdeReferencia [planes de referencia]
     */
    this.showLoader();

    this.allPlanningReference = this.$store.getters.allPlanningReference

    this.hideLoader();
  },
  computed: {

    validationErrors() {
      /**
       * [ Realiza la validacion de nombre y mandato los cuales son requeridos ]
       * @param {[name]} Nombre [nombre de referencia]
       * @param { [mandate] } Mandato [mandato]
       */
      return {
        name: [
          ...[
            !this.$v.newCase.name.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
        mandate: [
          ...[
            !this.$v.newCase.mandate.required
              ? "Este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    ...mapState(["dataSource", "user"]),
  },
  components: {},
};
</script>

<style scoped></style>
