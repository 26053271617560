import { render, staticRenderFns } from "./CreateCategories.vue?vue&type=template&id=249020d4&scoped=true&"
import script from "./CreateCategories.vue?vue&type=script&lang=js&"
export * from "./CreateCategories.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249020d4",
  null
  
)

export default component.exports